import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import { OilAndGasTabs } from "../../components/tabs/OilAndGasTabs";
import FullWidthCTA from "../../components/FullWidthCTA";
import Testimonials from "../../components/testimonials/Testimonials";

//RENDER
export default function OilAndGasPage() {
    const bannerImg = '../../banners/oil-and-gas-banner.jpg';
    const ctaBackgroundImg = '../../oil-and-gas/oil-and-gas_overview-banner.jpg';

    return (
        <Layout pageTitle={'Oil and Gas'}>
            <SplashBanner
                title={"Oil & Gas"}
                content={"Experience  | Knowledge  | Competency"}
                backgroundImg={bannerImg}
            />

            <section>
                <OilAndGasTabs />
            </section>

            <FullWidthCTA id={'oilAndGasOverview'} ctaBackgroundImg={ctaBackgroundImg}>
                <h3>Overview</h3>
                <p>For over a decade we have demonstrated industry leading technical knowledge across the Upstream
                    sector. This has allowed us to create bespoke solutions for our customers that combine our passion,
                    knowledge and experience to ensure that competency is not sacrificed when engaging a third party for
                    technical personnel.</p>
                <p>Our ability to hold in-depth technical discussions with our customers focusing on equipment
                    selection, reservoir, downhole & wellbore formation challenges helps customers reduce NPT, CAPEX &
                    OPEX expenditure while increasing competency and HSE standards on their projects.
                </p>
                <div className="double-button" style={{ marginTop: '40px' }}>
                    <a href={'../../brochures/performance-energy_oil-and-gas-brochure.pdf'} target="_blank"
                        rel="noopener noreferrer" className={`button inverted primary arrow-light`}>
                        <span>Download our brochure</span>
                    </a>
                    <Link to={'/contact-us'} className="button inverted inline arrow">
                        <span>Contact us</span>
                    </Link>
                </div>
            </FullWidthCTA>

            <Testimonials />

        </Layout>
    )
}
